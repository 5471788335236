import React from 'react';

// Styling
import { Container } from 'reactstrap';
import styles from './index.module.scss';
import classnames from 'classnames';

//Components
import MetaHead from '../../components/MetaHead/MetaHead';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import FetchSummits from '../../libs/FetchSummits/FetchSummits';
import FetchFeatured from 'apps/portal/libs/FetchFeatured/FetchFeatured';
import getBaseUrl from '../../libs/getBaseUrl';

/**
 * Function to prepare the params for featured summit.
 * @returns featured summit's params.
 */
function paramsToFetchDataFeaturedSummit() {
  return {
    start: 0,
    size: 1,
    rank: 'closest',
    bq: `(and type:'summit')`,
    rankClosest: 'abs(entrytime-time())',
  };
}
/**
 * Function to prepare the params for upcoming/recorded summits.
 * @param rank summit's datetime/-datetime.
 * @param status summit's upcoming/recorded.
 * @returns upcoming/recorded summit's params.
 */
function paramsToFetchDataSummits(rank: string, status: string) {
  return {
    start: 0,
    size: 6,
    rank: rank,
    bq: `(and type:'summit' status:'${status}')`,
    rankClosest: '',
  };
}

// This gets called on every request
export async function getServerSideProps() {
  return {
    props: {
      hostUrl: getBaseUrl(),
      summitUrl: global.process.env.SUMMITS_HOST,
      seriesUrl: global.process.env.SERIES_HOST,
      staticAssetUrl: global.process.env.STATIC_ASSETS_CDN_URL,
    },
  };
}

export default function Summits({ hostUrl, summitUrl, staticAssetUrl }) {
  // UTM params for featured summit.
  const featuredSummitUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-summits&utm_content=featured';
  // UTM params for upcoming summit.
  const upcomingSummitUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-summits&utm_content=upcoming';
  // UTM params for recent summit.
  const recentSummitUTM =
    '?utm_source=brighttalk-portal&utm_medium=web&utm_campaign=all-summits&utm_content=recent';


  const upcomingSummitsComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Upcoming Summits',
    priorityStatus: true,
    summitUrl,
    summitsUTM:upcomingSummitUTM,
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
  };


  const recordedSummitsComponentInfo = {
    rowColSm: 12,
    rowColMd: 6,
    rowColXl: 4,
    sectionHeading: true,
    sectionHeadingTitle: 'Recent Summits',
    priorityStatus: true,
    summitUrl,
    summitsUTM: recentSummitUTM,
    footLinkTitle: 'See agenda',
    gridItemName: 'summit-item',
    dataBddTitle: 'talks-title-summits',
    dataBddDesc: 'talks-card-description-summits',
  };

  const featuredSummitsComponentInfo = {
    priorityStatus: true,
    summitUrl,
    summitsUTM: featuredSummitUTM,
    type: 'summits',
    sectionTitle: 'Summit',
  };

  return (
    <>
      <MetaHead
        title="Summits - BrightTALK"
        pageName="Summits"
        staticAssetUrl={staticAssetUrl}
        hostUrl={hostUrl}
      />
      <HeroBanner
        pageType="page-banner"
        title="Summits"
        description="Every month, BrightTALK Summits attract thousands of professionals interested in leading-edge original content. Featuring insights from industry experts and visionary innovators, these free virtual events offer a learning experience more and more business people prefer. "
        search={false}
        theme={'dark'}
        hostUrl={hostUrl}
      />

      <Container
        className={classnames('bootstrap-container', styles['summits-page'])}
      >
        {FetchFeatured(
          paramsToFetchDataFeaturedSummit(),
          featuredSummitsComponentInfo
        )}
        {FetchSummits(
          hostUrl,
          paramsToFetchDataSummits('datetime', 'upcoming|live'),
          upcomingSummitsComponentInfo
        )}
        {FetchSummits(
          hostUrl,
          paramsToFetchDataSummits('-datetime', 'recorded'),
          recordedSummitsComponentInfo
        )}
      </Container>
    </>
  );
}
